import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Flex, ConfigProvider } from "antd";
import { lodash as _, DarkModeContext } from "@datwyler/mfe-shared-components";
import { TenantIdProvider } from "@datwyler/shared-components";
import Dashboard from "@containers/dashboard";
import { ThemeContextType } from "@types";

const App = () => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();

  const tenantId = _.get(location, "state.tenantId");

  return (
    <TenantIdProvider tenantId={tenantId}>
      <ConfigProvider theme={theme}>
        <Flex vertical gap={"middle"}>
          <Dashboard theme={theme} />
        </Flex>
      </ConfigProvider>
    </TenantIdProvider>
  );
};

export default App;
