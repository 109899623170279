import ReactECharts from "echarts-for-react";
import { useTheme } from "@datwyler/mfe-shared-components";
import withWidget from "@components/withWidget";
import SelectFilter from "@components/Filters/select";
import { getOptions } from "./echart";
import { StyledCard, StyledTitle } from "./styled";
import { MeterProps } from "@types";
const Meter = ({
  sites,
  selectedSite,
  setSelectedSite,
  title,
  value,
  maxValue,
  splitNumber,
}: MeterProps) => {
  const theme = useTheme();
  const Title = () => {
    return (
      <StyledTitle>
        <div className="meter-title">
          <span>{title}</span>
        </div>
        <SelectFilter
          options={sites}
          handleSelectChange={(siteId) => setSelectedSite(siteId)}
          value={selectedSite}
        />
      </StyledTitle>
    );
  };
  return (
    <StyledCard title={<Title />} bordered={false}>
      <ReactECharts option={getOptions(value, theme, maxValue, splitNumber)} />
    </StyledCard>
  );
};

export default withWidget(Meter);
