import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
// import { ConfigProvider } from "antd";
import {
  GraphQLApiProvider,
  TranslationProvider,
  NotificationProvider,
  DarkModeProvider,
  ContentWrapper,
  useLocale,
} from "@datwyler/mfe-shared-components";
import App from "./app";
const Root = ({ GRAPHQL_URL }: { GRAPHQL_URL: string }) => {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);
  return (
    <BrowserRouter>
      <TranslationProvider locale={locale}>
        <NotificationProvider>
          <GraphQLApiProvider uri={GRAPHQL_URL}>
            <DarkModeProvider>
              <ContentWrapper>
                <App />
              </ContentWrapper>
            </DarkModeProvider>
          </GraphQLApiProvider>
        </NotificationProvider>
      </TranslationProvider>
    </BrowserRouter>
  );
};

export default Root;
