import type { TableColumnsType, TableProps, MappingAlgorithm } from "antd";
import type { AliasToken, OverrideToken } from "antd/es/theme/interface";

export type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";
export type BreakpointMap = Partial<Record<Breakpoint, boolean>>;

export enum Severity {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  INFORMATION = "INFORMATION",
}

export enum EntityStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}
interface ThemeConfig<T = unknown> {
  token?: Partial<AliasToken> & T;
  components?: OverrideToken;
  algorithm?: MappingAlgorithm | MappingAlgorithm[];
  hashed?: boolean;
  inherit?: boolean;
}

export interface CustomThemePropType {
  datwylerBrand: Record<string, string>;
  datwylerText: Record<string, string>;
  datwylerBorder: Record<string, string>;
  datwylerIcon: Record<string, string>;
  datwylerSurface: Record<string, string>;
}

export type CustomThemeConfigProps = ThemeConfig<CustomThemePropType>;

export interface ThemeContextType {
  isDarkMode: boolean;
  isDarkModeAuto: boolean;
  theme: CustomThemeConfigProps;
}

export interface TabTitleType {
  label: string;
  value?: string;
  unit?: string;
}

export interface GridWidgets {
  Icon: React.FC<{
    style: { [name: string]: string | number };
    height: number;
    width: number;
    bgColor?: string;
  }>;
  value: string;
  name: string;
}

export type GridWidgetsType = GridWidgets[];

export interface CloseIconButtonProps {
  width: number;
  height: number;
  style: { [name: string]: string | number };
  onClick: (event) => void;
}

export interface AddWidgetModalProps {
  open: boolean;
  widgets: {
    label: string;
    value: string;
  }[];
  widgetList: string[];
  setWidgetList: React.Dispatch<React.SetStateAction<string[]>>;
  handleCancelWidget: () => void;
  handleAddWidget: (selectedWidgetlist: string[]) => void;
}

export interface WidgetTableProps {
  columns: TableColumnsType;
  data: TableProps["dataSource"];
  title?: () => JSX.Element;
}

export interface WidgetTabsProps {
  tabs: {
    title: string;
    value: string;
    unit: string;
    key: string;
    Component: React.FC;
  }[];
  defaultActiveKey: string;
  handleTabChange: (activeKey: string) => void;
}

export interface HumdityTempFilterProps {
  sites: { value: string; label: string }[];
  devices: { value: string; label: string }[];
  selectedPeriod: string;
  selectedSite: string;
  selectedDevice: string;
  handlePeriodChange: (value) => void;
  handleSiteChange: (value) => void;
  handleDeviceChange: (value) => void;
}

export interface GridWidgetsProps {
  widgets: GridWidgetsType;
  token: Partial<AliasToken> & CustomThemePropType;
}
export interface HeaderProps {
  gridWidgets: GridWidgetsType;
  handleAddWidget: (selectedWidgetlist: string[]) => void;
  widgetList: string[];
  setWidgetList: React.Dispatch<React.SetStateAction<string[]>>;
  resetWidgetList: () => void;
  token: Partial<AliasToken> & CustomThemePropType;
}

export interface MapProps {
  token?: Partial<AliasToken> & CustomThemePropType;
  locations: {
    country: { name: string };
    highestSeverity: Severity;
    latitude: number;
    longitude: number;
    name: string;
    status: EntityStatus;
    sites: { name: string; status: EntityStatus }[];
  }[];
}
// Widgets Component
export interface DeviceTableDataProps {
  siteName: string;
  siteStatus: string;
  device: string;
  alert: string;
  status: string;
}

export interface DeviceTableProps {
  sites: { value: string; label: string }[];
  selectedSite: string;
  actions: Array<React.ReactNode>;
  title: string;
  data: DeviceTableDataProps[];
  setSelectedSite: React.Dispatch<React.SetStateAction<string>>;
}

export interface Widgetsprops {
  commonProps: (name: string) => CommonWidgetProps;
  widgetList: string[];
}
export interface MeterProps {
  sites: { value: string; label: string }[];
  selectedSite: string;
  setSelectedSite: React.Dispatch<React.SetStateAction<string>>;
  actions: Array<React.ReactNode>;
  title: string;
  value: number;
  maxValue: number;
  splitNumber: number;
}

export interface CommonWidgetProps {
  sites: { value: string; label: string }[];
  actions: Array<React.ReactNode>;
  tenantID: string;
}

export interface UPSDeviceProps extends CommonWidgetProps {}

export interface CoolingUnitsProps extends CommonWidgetProps {}
export interface MainMeterProps extends CommonWidgetProps {}
export interface ITMeterProps extends CommonWidgetProps {}

export interface PUEMeterProps extends CommonWidgetProps {}

export interface HumdityTempChartProps extends CommonWidgetProps {}
