import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useWidgets, lodash as _ } from "@datwyler/mfe-shared-components";
import Meter from "@components/Meter";
import { PUEMeterProps } from "@types";

const PUEMeter = ({ sites, actions, tenantID }: PUEMeterProps) => {
  const intl = useIntl();
  const [selectedSite, setSelectedSite] = useState("");
  const { fetchPUEMeter, fetchPUEMeterData } = useWidgets();

  const getMeterReading = useCallback(() => {
    tenantID &&
      fetchPUEMeter({
        variables: {
          tenantId: tenantID,
          ...(selectedSite && { siteId: selectedSite }),
        },
      });
  }, [tenantID, fetchPUEMeter, selectedSite]);

  useEffect(() => {
    getMeterReading();
  }, [getMeterReading]);

  const value = _.get(fetchPUEMeterData, "pueReading.value", 2);

  return (
    <Meter
      sites={sites}
      selectedSite={selectedSite}
      setSelectedSite={setSelectedSite}
      actions={actions}
      title={intl.formatMessage({ id: "home_widget_pue" })}
      value={value}
      maxValue={5}
      splitNumber={5}
    />
  );
};

export default PUEMeter;
