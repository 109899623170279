import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Meter from "@components/Meter";
import { useWidgets, lodash as _ } from "@datwyler/mfe-shared-components";
import { ITMeterProps } from "@types";

const ITMeter = ({ sites, actions, tenantID }: ITMeterProps) => {
  const intl = useIntl();
  const [selectedSite, setSelectedSite] = useState("");
  const { fetchITMeter, fetchITMeterData, fetchMainMeter, fetchMainMeterData } =
    useWidgets();

  const getMeterReading = useCallback(() => {
    const payload = {
      variables: {
        tenantId: tenantID,
        ...(selectedSite && { siteId: selectedSite }),
      },
    };
    tenantID && fetchITMeter(payload);
    tenantID && fetchMainMeter(payload);
  }, [tenantID, fetchITMeter, selectedSite, fetchMainMeter]);

  useEffect(() => {
    getMeterReading();
  }, [getMeterReading]);

  const value = _.get(fetchITMeterData, "itMeterReading.value", 0);
  const maxValue = _.get(fetchMainMeterData, "mainMeterReading.value", 100);

  return (
    <Meter
      sites={sites}
      selectedSite={selectedSite}
      setSelectedSite={setSelectedSite}
      actions={actions}
      title={intl.formatMessage({ id: "home_widget_it_meter" })}
      value={value}
      maxValue={maxValue}
      splitNumber={10}
    />
  );
};

export default ITMeter;
