import { useEffect, useState, useCallback } from "react";
import {
  useTenant,
  useLocation,
  lodash as _,
} from "@datwyler/mfe-shared-components";

const useGridWidgets = (tenantId: string) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const {
    fetchTenantById,
    fetchTenantByIdData,
    updateTenant,
    updateTenantResponseData,
  } = useTenant();
  const { fetchLocationData, fetchLocations } = useLocation();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId, setTenantID]);

  const getWidgetsData = useCallback(() => {
    fetchTenantById({
      variables: { id: tenantID },
    });
  }, [fetchTenantById, tenantID]);

  const getSites = useCallback(() => {
    fetchLocations({
      variables: { tenantId: tenantID },
    });
  }, [fetchLocations, tenantID]);

  const updateWidgetList = (widgetList) => {
    const tenantDetails = _.get(fetchTenantByIdData, "tenantById", false);
    if (tenantDetails) {
      updateTenant({
        variables: {
          input: {
            id: tenantDetails.id,
            name: tenantDetails.name,
            status: tenantDetails.status,
            managed: tenantDetails.managed,
            dashboard: { widgets: widgetList },
          },
        },
      });
    }
  };

  useEffect(() => {
    if (tenantID) {
      getWidgetsData();
      getSites();
    }
  }, [tenantID, getWidgetsData, getSites]);

  return {
    widgetsData: fetchTenantByIdData,
    locationsData: _.get(fetchLocationData, "locations", []),
    updateWidgetList: updateWidgetList,
    updateTenantResponseData: updateTenantResponseData,
  };
};

export default useGridWidgets;
