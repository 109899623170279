export const getOptions = (value, theme, maxValue, splitNumber) => ({
  series: [
    {
      type: "gauge",
      startAngle: 180,
      endAngle: 0,
      min: 0,
      max: maxValue === 0 ? 100 : maxValue,
      splitNumber: splitNumber,
      grid: {
        height: 252,
      },
      itemStyle: {
        color: "#666666",
      },
      pointer: {
        length: "65%",
        width: 4,
        offsetCenter: [0, "-4%"],
      },
      axisLine: {
        lineStyle: {
          width: 30,
          color: [
            [
              value / (maxValue === 0 ? 100 : maxValue),
              theme.datwylerSurface.surfaceBrand2,
            ],
            [1, theme.datwylerBorder.borderPrimary],
          ],
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        length: 15,
        distance: -30,
        lineStyle: {
          color: "#fff",
          width: 2,
        },
      },
      radius: "100%",
      center: ["50%", "70%"],
      axisLabel: {
        show: false,
      },
      title: {
        show: false,
      },
      anchor: {
        show: true,
        showAbove: true,
        size: 12,
        offsetCenter: [0, "0%"],
        itemStyle: {
          borderWidth: 1,
          color: "#000000",
          borderColor: "#ffff",
        },
      },
      detail: {
        offsetCenter: [5, "50%"],
        valueAnimation: true,
        color: theme.datwylerText.textBrand,
        textStyle: {
          fontSize: "18px",
        },
        rich: {
          value: {
            color: theme.datwylerText.textBrand,
          },
        },
      },
      data: [
        {
          value: value.toFixed(2),
        },
      ],
    },
  ],
});
